import React, { Component } from 'react';
import Raven from "raven-js";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Raven.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 bg-white shadow-sm">
              <h1>An error has occurred.</h1>
              <p>Our team has been notified.</p>
            </div>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
